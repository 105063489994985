import domReady from '@wordpress/dom-ready';

function adjustStyleStickySocials() {
  const stickySocials = document.querySelectorAll(
    '.socials-sticky-bar .social-media-label, .socials-sticky-bar .social-media-divider'
  );
  const header = document.querySelector('.fullwidth-header');

  if (0 < stickySocials.length && header) {
    stickySocials.forEach(function (socialBtn) {
      const stickyPosition =
        socialBtn.getBoundingClientRect().top +
        document.documentElement.scrollTop;
      const stickyHeight = socialBtn.offsetHeight;
      const headerHeight = header.offsetHeight;
      const headerPosition = header.offsetTop;

      if (stickyPosition + stickyHeight * 2 > headerHeight + headerPosition) {
        socialBtn.classList.remove('white');
      } else {
        socialBtn.classList.add('white');
      }
    });
  }
}

domReady(() => {
  adjustStyleStickySocials();
});

window.addEventListener('scroll', function () {
  adjustStyleStickySocials();
});
